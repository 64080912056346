.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  transition: all 0.3s ease-out;
  z-index: 2;
  background-color: transparent;
  
  .container {
    width: $width;
    padding-left: 15px;
    padding-right: 15px;
    .row {
      display: flex;
      flex-direction: row;
      margin-left: -15px;
      margin-right: -15px;

      .logo-container {
        flex: 1;

        a {
          width: 171px;
          height: 55px;
          background-size: 171px 55px;
        }
      }

      .navigation {
        flex: 2;
        ul {
          display: flex;
          flex-direction: row;
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            color: #fff;
            line-height: 33px;
            font-size: 12px;
            font-family: "Poppins", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            text-transform: uppercase;

            &:hover {
              color: #009BCA;
            }
          }

          li + li {
            margin-left: 20px;
          }

          @media screen and (max-width: 768px) {
            li + li {
              margin-left: 0;
            }

            li {
              font-size: 14px;
              color: #333;
            }
          }

          a {
            outline: 0;
            text-decoration: none !important;
            color: inherit;
            transition: all 0.3s ease-out;
          } 
        }

        @media screen and (max-width: 768px) {
          ul {
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            background-color: #ecf0f1;
            width: 270px;
            align-items: flex-start;
            padding-top: 50px;
            padding-left: 50px;
            transition: all ease-out 300ms;
          }

          ul.hide {
            left: -320px;
          }
        }
      }

      .request-access-btn {
        flex: 1;
        text-align: right;
      }

      .mobile-menu-icon {
        display: none;
        position: absolute;
        right: 0;
        margin-right: 20px;
        margin-top: 10px;
        height: 24px;
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        .mobile-menu-icon {
          display: block;
        }
      }

      @media screen and (max-width: 990px) {
        .request-access-btn {
          display: none;
        }
      }

      .btn-outline-white {
        font-size: 12px;
        padding: 0px 35px 0 20px;
        margin-top: -2px;
        text-transform: inherit;
        border-width: 1px;
        position: relative;

        &:hover {
          color: #FFFFFF;
          background-color: #009BCA;
          border-color: #009BCA;
        }
    }
    }
  }

  @media screen and (max-width: 990px) {
    .container {
      width: 640px;
    }
  }
}

.header--scrolled {
  padding: 20px 0 10px;
  background-color: $header-bg-color;
}