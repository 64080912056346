section.subscribe-section {
  background-image: linear-gradient(-45deg, #009BCA, #1B1742);
  background-repeat: repeat-x;
  padding: 70px 0;

  .container {
    width: $width;
    margin: 0 auto;

    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text-container {
        text-align: center;
        color: #fff;
      }

      .input-container {
        display: flex;
        margin: 40px auto 0;
        max-width: 577px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        input {
          height: 55px;
          width: 65%;
          line-height: 1em;
          appearance: none;
          padding: 0 1em;
          font-size: 1.5rem;
          color: #1B1742;
          border: none;
          background-color: #F6F8FD;
          transition: all 0.3s ease-out;
          box-sizing: border-box;
          box-shadow: none;
        }

        button {
          flex: 1;
          width: 35%;
          box-shadow: none;
          color: #009BCA;
          margin-bottom: 0;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
          vertical-align: middle;
          touch-action: manipulation;
          background-image: none;
          border: 2px solid transparent;
          white-space: nowrap;
          transition: all 0.3s ease-out;
          padding: 6px 50px;
          font-size: 1.5rem;
          line-height: 38px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
}