.api-docs-portal-container {
  margin-top: 93px;
  background-image: $bg-img-portal;

  .container {
    width: 970px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 100px 0;

    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 250px;

      img {
        width: 175px;
      }

      .title {
        font-size: 2rem;
        margin: 20px 0 0 0;
        color: #fff;
      }

      .subtext:hover {
        opacity: 0.7;
      }

      p {
        border-top: solid 1px #fff;
        color: #fff;
        text-align: center;
        padding-top: 20px;
        width: 65%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .api-docs-portal-container {
    .container {
      width: $phone-width;
      flex-direction: column;
    }
  }
}
