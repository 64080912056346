section.contact-section {
  padding: 70px 0;
  .container {
    width: $width;
    margin: 0 auto;
    .row {
      display: flex;

      .image-container {
        width: $even-width;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
      }

      .text-container {
        width: $wider-width;
        padding: 0 15px;
        
        .title {
          font-size: 3rem;
          font-weight: 500;
          padding-bottom: 3rem;
          color: #1B1742;
          padding-left: 15px;
          padding-right: 15px;
        }

        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          list-style-type: none;
        }
      }

      .sub-component-container {
        display: flex;
        flex-direction: row;

        .sub-component {
          padding-left: 15px;
          padding-right: 15px;
        }

        a {
          text-transform: initial;
          color: $link-color;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  section.contact-section {
    .container {
      width: $small-width;
      .row {
        flex-direction: column-reverse;
        .sub-component-container {
          flex-direction: column;
        }
      }
    }
  }
}