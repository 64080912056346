section.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #1B1742 5%, transparent 700%);
  background-repeat: repeat-x;
  padding: 150px 0 150px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  
  .container {
    width: $width;
    text-align: center;
    color: #fff;
    
    img {
      transform: scale(1.36);
      max-width: 100%;
      height: auto;
    }

    h1 {
      position: relative;
      margin-top: -120px;
      z-index: 1;
    }
  }
}