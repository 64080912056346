@import './_variables';
@import './components-scss/_header';
@import './components-scss/_landing-page';
@import './components-scss/_footer';
@import './components-scss/_hero-section';
@import './components-scss/_subscribe';
@import './components-scss/_footer';
@import './components-scss/_contact';
@import './components-scss/_api-portal';
@import './components-scss/_api-docs';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  text-decoration: none !important;
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 10px;
}

body {
  
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  // font-size: 12px;
  font-weight: 400;
  line-height: 2.2rem;
  background-repeat: repeat-x;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1, h2, h3 {
  // letter-spacing: -0.02em;

}

h1, .h1 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 50px;
}

@media screen and (max-width: 700px) {
  h1, .h1 {
    font-size: 3.5rem;
  }
}

h2, .h2 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3rem;
}

h3, .h3 {
  margin-bottom: 20px;
  line-height: 1.25;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: $header-bg-color !important;
}

h4, .h4 {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-transform: uppercase;
  margin-top: 0;
  color: $header-bg-color;
}

h5, .h5 {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 2.2rem;
}

p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.hero--section {
  padding: 150px 0 115px;
}

span {
  position: relative !important;
}

input[type="submit"], input[type="reset"], input[type="button"], .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 2px solid transparent;
  white-space: nowrap;
  transition: all 0.3s ease-out;
  padding: 6px 50px;
  font-size: 1.5rem;
  line-height: 38px;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-outline-white {
  color: #FFFFFF;
  background-color: transparent;
  border-color: #FFFFFF;
  transition: all .3s ease-out;
  transition: all .3s ease-in-out;
}

section {
  background-image: #fff;
}

.custom-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.animated {
  animation-duration: 0 !important; 
  transition: all 150ms ease;
}

.hidden {
  display: none;
}