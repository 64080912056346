
section.landing-page {
  background-color: #fff;
  padding-top: 100px;

  .container {
    display: flex;
    flex-direction: row;
    width: $width;
    margin: 0 auto;

    ul.sticky-nav-container {
      position: sticky;
      top: 200px;
      height: 200px;
      flex: 1;
      list-style: none;
      margin: 0;
      padding: 0 40px 0 20px;
      li {
        display: block;
        position: relative;
        font-size: 1.5em;
        color: #1B1742;
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-right: 20px;
        border-bottom: 1px solid #1B1742;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 990px) {
      ul.sticky-nav-container {
        display: none;
      }
    }

    .content .landing-component:nth-child(even) .row {
        flex-direction: row-reverse;
      }

      @media screen and (max-width: 700px) {
        .content .landing-component:nth-child(even) .row {
          flex-direction: column;
        }
      }
    
    .content {
      flex: 4;
      
      .landing-component {
        padding-bottom: 100px;

        .row {
          display: flex;
          flex-direction: row;

          .text-content, .image-content {
            flex: 1;
            padding-left: 15px;
            padding-right: 15px;
          }
  
          .section-title {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.4rem;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
    
          .title {
            font-size: 3rem;
            font-weight: 400;
            line-height: 4rem;
            color: #1B1742;
          }
        }

        @media screen and (max-width: 700px) {
          .row {
            flex-direction: column;
          }
        }
      }

      .sub-components-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .sub-component {
          margin-top: 40px;
          // flex: 1;
          width: $even-width;
          max-width: 200px;
          padding-left: 15px;
          padding-right: 15px;

          .h4 {
            text-transform: initial;
          }
  
          .link {
            font-size: 1.5rem;
            float: left;
            padding-right: 27px;
            list-style: none;
            
            a {
              color: #009BCA;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    .container {
      width: $medium-width;
    }
  }

  @media screen and (max-width: 700px) {
    .container {
      width: $small-width;
    }
  }

  @media screen and (max-width: 520px) {
    .container {
      width: $phone-width;
    }
  }
}