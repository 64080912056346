section.footer-section {
  background-color: $header-bg-color;
  padding: 75px 0;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: $width;
    margin: 0 auto;
  }

  .left-container {
    width: $even-width;

    .text {
      color: #fff;
    }
  }

  .middle-container {
    width: $wider-width;

    ul {
      display: flex;
      flex-direction: row;
      color: #fff;
      list-style: none;
      justify-content: space-between;
      font-size: 1.4rem;

      ul.menu-list {
        display: flex;
        flex-direction: column;

        li.title {
          font-weight: 600;
          color: #fff;
          margin-bottom: 20px;
        }

        li {
          color: #9896AE;
          margin-bottom: 10px;
          transition: all ease 200ms;
          cursor: pointer;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  .right-container {
    width: $even-width;
  }

  .bottom-container {
    display: flex;
    flex-direction: row;
    width: $width;
    margin: 50px auto;
    color: #9896AE;

    .copyright {
      width: $even-width;
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style-type: none;
      padding: 0;
      width: $wider-width;

      li {
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  section.footer-section {
    .container, .bottom-container {
      width: $small-width;
    }
  }
}

@media screen and (max-width: 545px) {
  section.footer-section {

    .container {
      flex-direction: column;
      align-items: center;
    }
    
    .container, .bottom-container {
      width: $phone-width;
    }

    .middle-container {
      ul {
        flex-direction: column;
        padding-left: 0;
        align-items: center;
      }
    }
  }
}

