$background-image: linear-gradient(to bottom, #1B1742 5%, transparent 100%);
$header-bg-color: #1B1742;
$width: 970px;
$letter-spacing: 0.008em;
$even-width: 33.3333333333%;
$wider-width: 66.6666666667%;
$link-color: #009BCA;
$bg-img-portal: linear-gradient(-45deg, #009BCA, #1B1742);
$main-purple: #1B1742;
$green-bg-image: linear-gradient(-45deg, #B9E3EF, #DFEDCE);
$main-green: rgb(154, 195, 58);
$medium-width: 700px;
$small-width: 520px;
$phone-width: 320px;

.button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 2px solid transparent;
  white-space: nowrap;
  transition: all 0.3s ease-out;
  padding: 6px 50px;
  font-size: 1.2rem;
  line-height: 30px;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  color: #1B1742;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}