.api-docs-container {
  padding: 82px 0;
}

.top-nav-container {
  position: sticky;
  top: 93px;
  width: 100%;
  height: 40px;
  line-height: 41px;
  border-bottom: solid 1px #EEEEEE;
  background-color: #fff;
  z-index: 2;
  
   .row {
     display: flex;
     flex-direction: row;
     list-style-type: none;
     padding-left: 0;
    .top-nav-btn.active {
      a {
        background-color: rgba(154, 195, 58, .3);
      }
    }
     li {
       font-size: 1.5rem;
       a {
        background-color: #FAFAFA;
        padding: 10px 25px;
        border-right: solid 1px rgba(0,0,0, .08);
        transition: all ease 300ms;

        &:hover {
          opacity: 0.7;
        }
       }
       a.first-child {
        border-radius: 5px 0 0 5px;
       }

       a.last-child {
         border-right: none;
        border-radius: 0 5px 5px 0;
       }
     }
   }
}